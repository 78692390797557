import { inject, Injectable } from '@angular/core';
import { finalize, map, Observable } from 'rxjs';
import {
  GetJobTitleRolesPresenterModel,
  JobTitlePresenterModel,
  JobTitleRolesDTOPresenterModel,
  PermissionGroupPresenter,
  RolePresenterModel,
} from '../models/access-control-presenter.model';
import {
  getJobTitleRolesDTODataModel,
  getJobTitleRolesPresenterModel,
  getJobTitlesPresenterModel,
  getRolePresenterModel,
  mapPermissionToUiModel,
  mapRolePermissionToPresenterModel,
} from '../parsers/access-control-parser';
import { AppService } from '@core/services/app.service';
import { AccessControlUpdateRolesModel } from '@root/app/access-control/data-access/models/access-control-update-roles.model';
import { PagingResponse } from '@shared/model/page-response.model';
import { AccessControlRepositoryService } from '@root/app/access-control/data-access/services/access-control-repository.service';
import { JobTitlesListParamsModel } from '@root/app/access-control/data-access/models/job-titles-list-params.model';
import { UserGroupsFilterModel } from '@root/app/access-control/user-groups/data-access/models/user-groups-filter.model';
import {
  UserGroupsDetailPresenterModel,
  UserGroupsListItemPresenterModel,
  UserGroupsPresenterModel,
} from '@root/app/access-control/user-groups/data-access/models/user-groups-data.model';
import {
  getUserGroupsDataModel,
  getUserGroupsListItemDataModel,
  getUserGroupsMemberDetailPresenterModel,
} from '@root/app/access-control/user-groups/data-access/parsers/user-groups-parser';

@Injectable({
  providedIn: 'root',
})
export class AccessControlService {
  private readonly dataService = inject(AccessControlRepositoryService);
  // private readonly dataService = inject(AccessControlRepositoryMockService);

  private appService = inject(AppService);

  getUserData() {
    this.appService.updateFetchingState({ userData: true });
    return this.dataService
      .getUserData()
      .pipe(
        finalize(() => this.appService.updateFetchingState({ userData: false }))
      );
  }

  getEmployeePhotoFromActiveDirectory(employeeId: string) {
    return this.dataService.getEmployeePhotoFromActiveDirectory(employeeId);
  }

  // Features Management

  getRolesPermissions() {
    return this.dataService
      .getRolesPermissions()
      .pipe(map((roles) => roles.map(mapRolePermissionToPresenterModel)));
  }

  getPermissions(): Observable<PermissionGroupPresenter[]> {
    return this.dataService
      .getPermissions()
      .pipe(map((permissions) => permissions.map(mapPermissionToUiModel)));
  }

  updatePermissions(payload: AccessControlUpdateRolesModel) {
    return this.dataService.updatePermissions(payload);
  }

  // Job Titles Roles

  getJobTitles(
    params: JobTitlesListParamsModel
  ): Observable<PagingResponse<JobTitlePresenterModel>> {
    return this.dataService
      .getJobTitles(params)
      .pipe(map((data) => getJobTitlesPresenterModel(data)));
  }

  updateJobTitleRoles(
    jobTitleRoles: JobTitleRolesDTOPresenterModel
  ): Observable<boolean> {
    return this.dataService.updateJobTitleRoles(
      getJobTitleRolesDTODataModel(jobTitleRoles)
    );
  }

  getJobTitleRoles(
    jobTitleCode: number
  ): Observable<GetJobTitleRolesPresenterModel[]> {
    return this.dataService
      .getJobTitleRoles(jobTitleCode)
      .pipe(map((data) => getJobTitleRolesPresenterModel(data)));
  }

  getUserRolesList(): Observable<RolePresenterModel[]> {
    return this.dataService
      .getUserRolesList()
      .pipe(map((data) => getRolePresenterModel(data)));
  }

  getUserPhoto() {
    return this.dataService.getUserPhoto();
  }

  getUserGroupsByQuery(
    model: UserGroupsFilterModel
  ): Observable<PagingResponse<UserGroupsListItemPresenterModel>> {
    return this.dataService.getUserGroupsByQuery(model).pipe(
      map((res) => ({
        ...res,
        list: res.list.map(getUserGroupsListItemDataModel),
      }))
    );
  }

  deleteUserGroupById(id: number) {
    return this.dataService.deleteUserGroupById(id);
  }

  addUserGroup(model: UserGroupsPresenterModel) {
    return this.dataService.addUserGroup(getUserGroupsDataModel(model));
  }

  updateUserGroup(id: number, model: UserGroupsPresenterModel) {
    return this.dataService.updateUserGroup(id, getUserGroupsDataModel(model));
  }

  getUserGroupById(id: number): Observable<UserGroupsDetailPresenterModel> {
    return this.dataService.getUserGroupById(id).pipe(
      map((detail) => {
        return {
          ...detail,
          userGroupMembers: detail.userGroupMembers.map(
            getUserGroupsMemberDetailPresenterModel
          ),
        };
      })
    );
  }
}
