import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@root/environments/environment';
import { Observable } from 'rxjs';
import { AccessControlRepositoryInterface } from '../models/access-control-repository.model';
import {
  JobTitleDataModel,
  JobTitleRolesDataModel,
  JobTitleRolesDTOModel,
  PermissionGroupDataModel,
  RoleDataModel,
  RolePermissionsDataModel,
} from '@root/app/access-control/data-access/models/access-control-data.model';
import { UserData } from '@root/app/access-control/data-access/models/user-data.model';
import { AccessControlUpdateRolesModel } from '@root/app/access-control/data-access/models/access-control-update-roles.model';
import { PagingResponse } from '@shared/model/page-response.model';
import { isNil, omitBy } from 'lodash-es';
import { JobTitlesListParamsModel } from '@root/app/access-control/data-access/models/job-titles-list-params.model';
import { UserGroupsFilterModel } from '@root/app/access-control/user-groups/data-access/models/user-groups-filter.model';
import {
  UserGroupsDataModel,
  UserGroupsDetailDataModel,
  UserGroupsListItemDataModel,
} from '@root/app/access-control/user-groups/data-access/models/user-groups-data.model';

@Injectable({
  providedIn: 'root',
})
export class AccessControlRepositoryService
  implements AccessControlRepositoryInterface
{
  private http = inject(HttpClient);
  private readonly baseUrl = `${environment.apiUrl}/v2/AccessControl`;
  private readonly baseUserGroupsUrl = `${this.baseUrl}/UserGroups`;

  // These functions are moved from old service and don't satisfy data-access models
  getUserData() {
    return this.http.get<UserData>(`${this.baseUrl}/Users/Data`);
  }

  getRolesPermissions() {
    return this.http.get<RolePermissionsDataModel[]>(
      `${this.baseUrl}/Permissions/GetRolesPermissions`
    );
  }

  getPermissions() {
    return this.http.get<PermissionGroupDataModel[]>(
      `${this.baseUrl}/Permissions`
    );
  }

  updatePermissions(payload: AccessControlUpdateRolesModel) {
    return this.http.patch<string>(`${this.baseUrl}/Permissions`, payload);
  }

  getEmployeePhotoFromActiveDirectory(employeeId: string) {
    return this.http.get(`${this.baseUrl}/Users/${employeeId}/Photo`, {
      responseType: 'blob',
    });
  }

  // Job Titles Roles

  getJobTitles(
    params: JobTitlesListParamsModel
  ): Observable<PagingResponse<JobTitleDataModel>> {
    const truthyParams = omitBy(params, isNil);
    const httpParams = new HttpParams().appendAll(
      truthyParams as unknown as {
        [param: string]: string | number | boolean;
      }
    );
    return this.http.get<PagingResponse<JobTitleDataModel>>(
      `${this.baseUrl}/JobTitles`,
      {
        params: httpParams,
      }
    );
  }

  updateJobTitleRoles(data: JobTitleRolesDTOModel): Observable<boolean> {
    return this.http.patch<boolean>(`${this.baseUrl}/JobTitles`, data);
  }

  getJobTitleRoles(jobTitleCode: number): Observable<JobTitleRolesDataModel[]> {
    return this.http.get<JobTitleRolesDataModel[]>(
      `${this.baseUrl}/JobTitles/${jobTitleCode}/Roles`
    );
  }

  getUserRolesList(): Observable<RoleDataModel[]> {
    return this.http.get<RoleDataModel[]>(`${this.baseUrl}/UserRoles/List`);
  }

  getUserPhoto() {
    return this.http.get(`${this.baseUrl}/Users/Photo`, {
      responseType: 'blob',
    });
  }

  getUserGroupsByQuery(model: UserGroupsFilterModel) {
    return this.http.post<PagingResponse<UserGroupsListItemDataModel>>(
      `${this.baseUserGroupsUrl}/Search`,
      model
    );
  }

  deleteUserGroupById(id: number) {
    return this.http.delete(`${this.baseUserGroupsUrl}/${id}`);
  }

  addUserGroup(model: UserGroupsDataModel) {
    return this.http.post<number>(this.baseUserGroupsUrl, model);
  }

  updateUserGroup(id: number, model: UserGroupsDataModel) {
    return this.http.put<number>(`${this.baseUserGroupsUrl}/${id}`, model);
  }

  getUserGroupById(id: number) {
    return this.http.get<UserGroupsDetailDataModel>(
      `${this.baseUserGroupsUrl}/${id}`
    );
  }
}
