import {
  UserGroupsDataModel,
  UserGroupsListItemDataModel,
  UserGroupsListItemPresenterModel,
  UserGroupsMemberDetailDataModel,
  UserGroupsMemberDetailPresenterModel,
  UserGroupsPresenterModel,
} from '@root/app/access-control/user-groups/data-access/models/user-groups-data.model';

export const getUserGroupsListItemDataModel = (
  model: UserGroupsListItemPresenterModel
): UserGroupsListItemDataModel => {
  return {
    ...model,
  };
};

export const getUserGroupsDataModel = (
  model: UserGroupsPresenterModel
): UserGroupsDataModel => {
  return { ...model };
};

export const getUserGroupsMemberDetailPresenterModel = (
  model: UserGroupsMemberDetailDataModel
): UserGroupsMemberDetailPresenterModel => {
  return {
    ...model,
  };
};
