import {
  JobTitleDataModel,
  JobTitleRolesDataModel,
  JobTitleRolesDTOModel,
  PermissionGroupDataModel,
  RoleDataModel,
  RolePermissionsDataModel,
} from '@root/app/access-control/data-access/models/access-control-data.model';
import {
  GetJobTitleRolesPresenterModel,
  JobTitlePresenterModel,
  JobTitleRolesDTOPresenterModel,
  PermissionGroupPresenter,
  RolePermissionsPresenterModel,
  RolePresenterModel,
} from '@root/app/access-control/data-access/models/access-control-presenter.model';
import { PagingResponse } from '@shared/model/page-response.model';

export function getJobTitlesPresenterModel(
  model: PagingResponse<JobTitleDataModel>
): PagingResponse<JobTitlePresenterModel> {
  return {
    ...model,
    list: model.list.map((item) => {
      return { key: item.code, description: item.title };
    }),
  };
}

export function getJobTitleRolesDTODataModel(
  model: JobTitleRolesDTOPresenterModel
): JobTitleRolesDTOModel {
  return { ...model };
}

export function getJobTitleRolesPresenterModel(
  data: JobTitleRolesDataModel[]
): GetJobTitleRolesPresenterModel[] {
  return [...data];
}

export function getRolePresenterModel(
  data: RoleDataModel[]
): RolePresenterModel[] {
  return [...data];
}

export function mapRolePermissionToPresenterModel(
  role: RolePermissionsDataModel
): RolePermissionsPresenterModel {
  return {
    key: role.role.key,
    description: role.role.description,
    permissionKeys: role.permissionKeys,
  };
}

export function mapPermissionToUiModel(
  permission: PermissionGroupDataModel
): PermissionGroupPresenter {
  return {
    moduleName: permission.moduleName,
    moduleDisplayName: permission.moduleDisplayName,
    modulePermissions: permission.permissions.map((item) => {
      return {
        permission: item,
        showCopyIcon: undefined,
      };
    }),
  };
}
